/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNboRecBacktestResult = /* GraphQL */ `
  mutation CreateNboRecBacktestResult(
    $input: CreateNboRecBacktestResultInput!
    $condition: ModelNboRecBacktestResultConditionInput
  ) {
    createNboRecBacktestResult(input: $input, condition: $condition) {
      id
      purchase_train
      bp_id
      recommendations
      purchase_test
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNboRecBacktestResult = /* GraphQL */ `
  mutation UpdateNboRecBacktestResult(
    $input: UpdateNboRecBacktestResultInput!
    $condition: ModelNboRecBacktestResultConditionInput
  ) {
    updateNboRecBacktestResult(input: $input, condition: $condition) {
      id
      purchase_train
      bp_id
      recommendations
      purchase_test
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNboRecBacktestResult = /* GraphQL */ `
  mutation DeleteNboRecBacktestResult(
    $input: DeleteNboRecBacktestResultInput!
    $condition: ModelNboRecBacktestResultConditionInput
  ) {
    deleteNboRecBacktestResult(input: $input, condition: $condition) {
      id
      purchase_train
      bp_id
      recommendations
      purchase_test
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNboSurveyAnswers = /* GraphQL */ `
  mutation CreateNboSurveyAnswers(
    $input: CreateNboSurveyAnswersInput!
    $condition: ModelNboSurveyAnswersConditionInput
  ) {
    createNboSurveyAnswers(input: $input, condition: $condition) {
      id
      result
      nboRecBacktestResultID
      createdBy
      nboRecBacktestResult {
        id
        purchase_train
        bp_id
        recommendations
        purchase_test
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      nboSurveyAnswersNboRecBacktestResultId
      __typename
    }
  }
`;
export const updateNboSurveyAnswers = /* GraphQL */ `
  mutation UpdateNboSurveyAnswers(
    $input: UpdateNboSurveyAnswersInput!
    $condition: ModelNboSurveyAnswersConditionInput
  ) {
    updateNboSurveyAnswers(input: $input, condition: $condition) {
      id
      result
      nboRecBacktestResultID
      createdBy
      nboRecBacktestResult {
        id
        purchase_train
        bp_id
        recommendations
        purchase_test
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      nboSurveyAnswersNboRecBacktestResultId
      __typename
    }
  }
`;
export const deleteNboSurveyAnswers = /* GraphQL */ `
  mutation DeleteNboSurveyAnswers(
    $input: DeleteNboSurveyAnswersInput!
    $condition: ModelNboSurveyAnswersConditionInput
  ) {
    deleteNboSurveyAnswers(input: $input, condition: $condition) {
      id
      result
      nboRecBacktestResultID
      createdBy
      nboRecBacktestResult {
        id
        purchase_train
        bp_id
        recommendations
        purchase_test
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      nboSurveyAnswersNboRecBacktestResultId
      __typename
    }
  }
`;
export const createMarketingTypeGroup = /* GraphQL */ `
  mutation CreateMarketingTypeGroup(
    $input: CreateMarketingTypeGroupInput!
    $condition: ModelMarketingTypeGroupConditionInput
  ) {
    createMarketingTypeGroup(input: $input, condition: $condition) {
      id
      code
      name
      description
      marketingTypes {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMarketingTypeGroup = /* GraphQL */ `
  mutation UpdateMarketingTypeGroup(
    $input: UpdateMarketingTypeGroupInput!
    $condition: ModelMarketingTypeGroupConditionInput
  ) {
    updateMarketingTypeGroup(input: $input, condition: $condition) {
      id
      code
      name
      description
      marketingTypes {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMarketingTypeGroup = /* GraphQL */ `
  mutation DeleteMarketingTypeGroup(
    $input: DeleteMarketingTypeGroupInput!
    $condition: ModelMarketingTypeGroupConditionInput
  ) {
    deleteMarketingTypeGroup(input: $input, condition: $condition) {
      id
      code
      name
      description
      marketingTypes {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createArticleType = /* GraphQL */ `
  mutation CreateArticleType(
    $input: CreateArticleTypeInput!
    $condition: ModelArticleTypeConditionInput
  ) {
    createArticleType(input: $input, condition: $condition) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticleType = /* GraphQL */ `
  mutation UpdateArticleType(
    $input: UpdateArticleTypeInput!
    $condition: ModelArticleTypeConditionInput
  ) {
    updateArticleType(input: $input, condition: $condition) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticleType = /* GraphQL */ `
  mutation DeleteArticleType(
    $input: DeleteArticleTypeInput!
    $condition: ModelArticleTypeConditionInput
  ) {
    deleteArticleType(input: $input, condition: $condition) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverwriteContractPayedRatio = /* GraphQL */ `
  mutation CreateOverwriteContractPayedRatio(
    $input: CreateOverwriteContractPayedRatioInput!
    $condition: ModelOverwriteContractPayedRatioConditionInput
  ) {
    createOverwriteContractPayedRatio(input: $input, condition: $condition) {
      id
      contractPayedRatioTheshold
      minMax
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      reason
      approvedControllingBy
      validFrom
      validTill
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverwriteContractPayedRatio = /* GraphQL */ `
  mutation UpdateOverwriteContractPayedRatio(
    $input: UpdateOverwriteContractPayedRatioInput!
    $condition: ModelOverwriteContractPayedRatioConditionInput
  ) {
    updateOverwriteContractPayedRatio(input: $input, condition: $condition) {
      id
      contractPayedRatioTheshold
      minMax
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      reason
      approvedControllingBy
      validFrom
      validTill
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverwriteContractPayedRatio = /* GraphQL */ `
  mutation DeleteOverwriteContractPayedRatio(
    $input: DeleteOverwriteContractPayedRatioInput!
    $condition: ModelOverwriteContractPayedRatioConditionInput
  ) {
    deleteOverwriteContractPayedRatio(input: $input, condition: $condition) {
      id
      contractPayedRatioTheshold
      minMax
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      reason
      approvedControllingBy
      validFrom
      validTill
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCondition = /* GraphQL */ `
  mutation CreateCondition(
    $input: CreateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    createCondition(input: $input, condition: $condition) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCondition = /* GraphQL */ `
  mutation UpdateCondition(
    $input: UpdateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    updateCondition(input: $input, condition: $condition) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCondition = /* GraphQL */ `
  mutation DeleteCondition(
    $input: DeleteConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    deleteCondition(input: $input, condition: $condition) {
      id
      code
      name
      description
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverwriteMonthInAboNull = /* GraphQL */ `
  mutation CreateOverwriteMonthInAboNull(
    $input: CreateOverwriteMonthInAboNullInput!
    $condition: ModelOverwriteMonthInAboNullConditionInput
  ) {
    createOverwriteMonthInAboNull(input: $input, condition: $condition) {
      id
      minMax
      monthThreshold
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      validFrom
      validTill
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      reason
      approvedControllingBy
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverwriteMonthInAboNull = /* GraphQL */ `
  mutation UpdateOverwriteMonthInAboNull(
    $input: UpdateOverwriteMonthInAboNullInput!
    $condition: ModelOverwriteMonthInAboNullConditionInput
  ) {
    updateOverwriteMonthInAboNull(input: $input, condition: $condition) {
      id
      minMax
      monthThreshold
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      validFrom
      validTill
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      reason
      approvedControllingBy
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverwriteMonthInAboNull = /* GraphQL */ `
  mutation DeleteOverwriteMonthInAboNull(
    $input: DeleteOverwriteMonthInAboNullInput!
    $condition: ModelOverwriteMonthInAboNullConditionInput
  ) {
    deleteOverwriteMonthInAboNull(input: $input, condition: $condition) {
      id
      minMax
      monthThreshold
      conditionID
      condition {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      createdBy
      validFrom
      validTill
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      reason
      approvedControllingBy
      approvedPublisherBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrderCompensation = /* GraphQL */ `
  mutation CreateOrderCompensation(
    $input: CreateOrderCompensationInput!
    $condition: ModelOrderCompensationConditionInput
  ) {
    createOrderCompensation(input: $input, condition: $condition) {
      id
      price
      validFrom
      description
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedConsultingBy
      approvedConsultingOn
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrderCompensation = /* GraphQL */ `
  mutation UpdateOrderCompensation(
    $input: UpdateOrderCompensationInput!
    $condition: ModelOrderCompensationConditionInput
  ) {
    updateOrderCompensation(input: $input, condition: $condition) {
      id
      price
      validFrom
      description
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedConsultingBy
      approvedConsultingOn
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrderCompensation = /* GraphQL */ `
  mutation DeleteOrderCompensation(
    $input: DeleteOrderCompensationInput!
    $condition: ModelOrderCompensationConditionInput
  ) {
    deleteOrderCompensation(input: $input, condition: $condition) {
      id
      price
      validFrom
      description
      marketingTypeID
      marketingType {
        id
        code
        description
        marketingTypeGroupID
        isOrderConsumption
        isNGW
        isPsb
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedConsultingBy
      approvedConsultingOn
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverwriteArticlePrice = /* GraphQL */ `
  mutation CreateOverwriteArticlePrice(
    $input: CreateOverwriteArticlePriceInput!
    $condition: ModelOverwriteArticlePriceConditionInput
  ) {
    createOverwriteArticlePrice(input: $input, condition: $condition) {
      id
      priceThreshold
      validFrom
      validTill
      createdBy
      description
      minMax
      reason
      type
      approvedControllingBy
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedPublisherBy
      approvedDataBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverwriteArticlePrice = /* GraphQL */ `
  mutation UpdateOverwriteArticlePrice(
    $input: UpdateOverwriteArticlePriceInput!
    $condition: ModelOverwriteArticlePriceConditionInput
  ) {
    updateOverwriteArticlePrice(input: $input, condition: $condition) {
      id
      priceThreshold
      validFrom
      validTill
      createdBy
      description
      minMax
      reason
      type
      approvedControllingBy
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedPublisherBy
      approvedDataBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverwriteArticlePrice = /* GraphQL */ `
  mutation DeleteOverwriteArticlePrice(
    $input: DeleteOverwriteArticlePriceInput!
    $condition: ModelOverwriteArticlePriceConditionInput
  ) {
    deleteOverwriteArticlePrice(input: $input, condition: $condition) {
      id
      priceThreshold
      validFrom
      validTill
      createdBy
      description
      minMax
      reason
      type
      approvedControllingBy
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      approvedPublisherBy
      approvedDataBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMarketingType = /* GraphQL */ `
  mutation CreateMarketingType(
    $input: CreateMarketingTypeInput!
    $condition: ModelMarketingTypeConditionInput
  ) {
    createMarketingType(input: $input, condition: $condition) {
      id
      code
      description
      marketingTypeGroup {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeGroupID
      isOrderConsumption
      isNGW
      isPsb
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMarketingType = /* GraphQL */ `
  mutation UpdateMarketingType(
    $input: UpdateMarketingTypeInput!
    $condition: ModelMarketingTypeConditionInput
  ) {
    updateMarketingType(input: $input, condition: $condition) {
      id
      code
      description
      marketingTypeGroup {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeGroupID
      isOrderConsumption
      isNGW
      isPsb
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMarketingType = /* GraphQL */ `
  mutation DeleteMarketingType(
    $input: DeleteMarketingTypeInput!
    $condition: ModelMarketingTypeConditionInput
  ) {
    deleteMarketingType(input: $input, condition: $condition) {
      id
      code
      description
      marketingTypeGroup {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      marketingTypeGroupID
      isOrderConsumption
      isNGW
      isPsb
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      code
      title
      articleType {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleTypeID
      number
      isActive
      mandatorCode
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      code
      title
      articleType {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleTypeID
      number
      isActive
      mandatorCode
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      code
      title
      articleType {
        id
        code
        name
        description
        createdBy
        createdAt
        updatedAt
        __typename
      }
      articleTypeID
      number
      isActive
      mandatorCode
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPriceSegmentOverwrite = /* GraphQL */ `
  mutation CreatePriceSegmentOverwrite(
    $input: CreatePriceSegmentOverwriteInput!
    $condition: ModelPriceSegmentOverwriteConditionInput
  ) {
    createPriceSegmentOverwrite(input: $input, condition: $condition) {
      id
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      priceSegment
      reason
      validFrom
      validTill
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePriceSegmentOverwrite = /* GraphQL */ `
  mutation UpdatePriceSegmentOverwrite(
    $input: UpdatePriceSegmentOverwriteInput!
    $condition: ModelPriceSegmentOverwriteConditionInput
  ) {
    updatePriceSegmentOverwrite(input: $input, condition: $condition) {
      id
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      priceSegment
      reason
      validFrom
      validTill
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePriceSegmentOverwrite = /* GraphQL */ `
  mutation DeletePriceSegmentOverwrite(
    $input: DeletePriceSegmentOverwriteInput!
    $condition: ModelPriceSegmentOverwriteConditionInput
  ) {
    deletePriceSegmentOverwrite(input: $input, condition: $condition) {
      id
      articleID
      article {
        id
        code
        title
        articleTypeID
        number
        isActive
        mandatorCode
        createdBy
        createdAt
        updatedAt
        __typename
      }
      priceSegment
      reason
      validFrom
      validTill
      createdBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAffiliateHardMapping = /* GraphQL */ `
  mutation CreateAffiliateHardMapping(
    $input: CreateAffiliateHardMappingInput!
    $condition: ModelAffiliateHardMappingConditionInput
  ) {
    createAffiliateHardMapping(input: $input, condition: $condition) {
      id
      affiliateSource
      affiliateTarget
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAffiliateHardMapping = /* GraphQL */ `
  mutation UpdateAffiliateHardMapping(
    $input: UpdateAffiliateHardMappingInput!
    $condition: ModelAffiliateHardMappingConditionInput
  ) {
    updateAffiliateHardMapping(input: $input, condition: $condition) {
      id
      affiliateSource
      affiliateTarget
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAffiliateHardMapping = /* GraphQL */ `
  mutation DeleteAffiliateHardMapping(
    $input: DeleteAffiliateHardMappingInput!
    $condition: ModelAffiliateHardMappingConditionInput
  ) {
    deleteAffiliateHardMapping(input: $input, condition: $condition) {
      id
      affiliateSource
      affiliateTarget
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCooperationCost = /* GraphQL */ `
  mutation CreateCooperationCost(
    $input: CreateCooperationCostInput!
    $condition: ModelCooperationCostConditionInput
  ) {
    createCooperationCost(input: $input, condition: $condition) {
      id
      affiliate
      costType
      cost
      totalCost
      fromDate
      untilDate
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCooperationCost = /* GraphQL */ `
  mutation UpdateCooperationCost(
    $input: UpdateCooperationCostInput!
    $condition: ModelCooperationCostConditionInput
  ) {
    updateCooperationCost(input: $input, condition: $condition) {
      id
      affiliate
      costType
      cost
      totalCost
      fromDate
      untilDate
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCooperationCost = /* GraphQL */ `
  mutation DeleteCooperationCost(
    $input: DeleteCooperationCostInput!
    $condition: ModelCooperationCostConditionInput
  ) {
    deleteCooperationCost(input: $input, condition: $condition) {
      id
      affiliate
      costType
      cost
      totalCost
      fromDate
      untilDate
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNetworkType = /* GraphQL */ `
  mutation CreateNetworkType(
    $input: CreateNetworkTypeInput!
    $condition: ModelNetworkTypeConditionInput
  ) {
    createNetworkType(input: $input, condition: $condition) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNetworkType = /* GraphQL */ `
  mutation UpdateNetworkType(
    $input: UpdateNetworkTypeInput!
    $condition: ModelNetworkTypeConditionInput
  ) {
    updateNetworkType(input: $input, condition: $condition) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNetworkType = /* GraphQL */ `
  mutation DeleteNetworkType(
    $input: DeleteNetworkTypeInput!
    $condition: ModelNetworkTypeConditionInput
  ) {
    deleteNetworkType(input: $input, condition: $condition) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAffiliateType = /* GraphQL */ `
  mutation CreateAffiliateType(
    $input: CreateAffiliateTypeInput!
    $condition: ModelAffiliateTypeConditionInput
  ) {
    createAffiliateType(input: $input, condition: $condition) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAffiliateType = /* GraphQL */ `
  mutation UpdateAffiliateType(
    $input: UpdateAffiliateTypeInput!
    $condition: ModelAffiliateTypeConditionInput
  ) {
    updateAffiliateType(input: $input, condition: $condition) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAffiliateType = /* GraphQL */ `
  mutation DeleteAffiliateType(
    $input: DeleteAffiliateTypeInput!
    $condition: ModelAffiliateTypeConditionInput
  ) {
    deleteAffiliateType(input: $input, condition: $condition) {
      id
      cd
      description
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgency = /* GraphQL */ `
  mutation CreateAgency(
    $input: CreateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    createAgency(input: $input, condition: $condition) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAgency = /* GraphQL */ `
  mutation UpdateAgency(
    $input: UpdateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    updateAgency(input: $input, condition: $condition) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAgency = /* GraphQL */ `
  mutation DeleteAgency(
    $input: DeleteAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    deleteAgency(input: $input, condition: $condition) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgencyCost = /* GraphQL */ `
  mutation CreateAgencyCost(
    $input: CreateAgencyCostInput!
    $condition: ModelAgencyCostConditionInput
  ) {
    createAgencyCost(input: $input, condition: $condition) {
      id
      agency {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      network {
        id
        cd
        name
        channelCd
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      affiliateType {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      newsletter {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        newsletterTargetGroupId
        __typename
      }
      fromDate
      freeText
      totalCost
      createdBy
      sortKey
      createdAt
      updatedAt
      agencyCostAgencyId
      agencyCostNetworkId
      agencyCostAffiliateTypeId
      agencyCostTargetGroupId
      agencyCostSubpublisherId
      agencyCostNewsletterId
      __typename
    }
  }
`;
export const updateAgencyCost = /* GraphQL */ `
  mutation UpdateAgencyCost(
    $input: UpdateAgencyCostInput!
    $condition: ModelAgencyCostConditionInput
  ) {
    updateAgencyCost(input: $input, condition: $condition) {
      id
      agency {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      network {
        id
        cd
        name
        channelCd
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      affiliateType {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      newsletter {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        newsletterTargetGroupId
        __typename
      }
      fromDate
      freeText
      totalCost
      createdBy
      sortKey
      createdAt
      updatedAt
      agencyCostAgencyId
      agencyCostNetworkId
      agencyCostAffiliateTypeId
      agencyCostTargetGroupId
      agencyCostSubpublisherId
      agencyCostNewsletterId
      __typename
    }
  }
`;
export const deleteAgencyCost = /* GraphQL */ `
  mutation DeleteAgencyCost(
    $input: DeleteAgencyCostInput!
    $condition: ModelAgencyCostConditionInput
  ) {
    deleteAgencyCost(input: $input, condition: $condition) {
      id
      agency {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      network {
        id
        cd
        name
        channelCd
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      affiliateType {
        id
        cd
        description
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      newsletter {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        newsletterTargetGroupId
        __typename
      }
      fromDate
      freeText
      totalCost
      createdBy
      sortKey
      createdAt
      updatedAt
      agencyCostAgencyId
      agencyCostNetworkId
      agencyCostAffiliateTypeId
      agencyCostTargetGroupId
      agencyCostSubpublisherId
      agencyCostNewsletterId
      __typename
    }
  }
`;
export const createNetwork = /* GraphQL */ `
  mutation CreateNetwork(
    $input: CreateNetworkInput!
    $condition: ModelNetworkConditionInput
  ) {
    createNetwork(input: $input, condition: $condition) {
      id
      cd
      name
      channelCd
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNetwork = /* GraphQL */ `
  mutation UpdateNetwork(
    $input: UpdateNetworkInput!
    $condition: ModelNetworkConditionInput
  ) {
    updateNetwork(input: $input, condition: $condition) {
      id
      cd
      name
      channelCd
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNetwork = /* GraphQL */ `
  mutation DeleteNetwork(
    $input: DeleteNetworkInput!
    $condition: ModelNetworkConditionInput
  ) {
    deleteNetwork(input: $input, condition: $condition) {
      id
      cd
      name
      channelCd
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTargetGroup = /* GraphQL */ `
  mutation CreateTargetGroup(
    $input: CreateTargetGroupInput!
    $condition: ModelTargetGroupConditionInput
  ) {
    createTargetGroup(input: $input, condition: $condition) {
      id
      name
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      targetGroupSubpublisherId
      __typename
    }
  }
`;
export const updateTargetGroup = /* GraphQL */ `
  mutation UpdateTargetGroup(
    $input: UpdateTargetGroupInput!
    $condition: ModelTargetGroupConditionInput
  ) {
    updateTargetGroup(input: $input, condition: $condition) {
      id
      name
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      targetGroupSubpublisherId
      __typename
    }
  }
`;
export const deleteTargetGroup = /* GraphQL */ `
  mutation DeleteTargetGroup(
    $input: DeleteTargetGroupInput!
    $condition: ModelTargetGroupConditionInput
  ) {
    deleteTargetGroup(input: $input, condition: $condition) {
      id
      name
      subpublisher {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        subpublisherPlanningDivisionId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      targetGroupSubpublisherId
      __typename
    }
  }
`;
export const createNewsletter = /* GraphQL */ `
  mutation CreateNewsletter(
    $input: CreateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    createNewsletter(input: $input, condition: $condition) {
      id
      cd
      name
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      newsletterTargetGroupId
      __typename
    }
  }
`;
export const updateNewsletter = /* GraphQL */ `
  mutation UpdateNewsletter(
    $input: UpdateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    updateNewsletter(input: $input, condition: $condition) {
      id
      cd
      name
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      newsletterTargetGroupId
      __typename
    }
  }
`;
export const deleteNewsletter = /* GraphQL */ `
  mutation DeleteNewsletter(
    $input: DeleteNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    deleteNewsletter(input: $input, condition: $condition) {
      id
      cd
      name
      targetGroup {
        id
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        targetGroupSubpublisherId
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      newsletterTargetGroupId
      __typename
    }
  }
`;
export const createSubpublisher = /* GraphQL */ `
  mutation CreateSubpublisher(
    $input: CreateSubpublisherInput!
    $condition: ModelSubpublisherConditionInput
  ) {
    createSubpublisher(input: $input, condition: $condition) {
      id
      cd
      name
      planningDivision {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      subpublisherPlanningDivisionId
      __typename
    }
  }
`;
export const updateSubpublisher = /* GraphQL */ `
  mutation UpdateSubpublisher(
    $input: UpdateSubpublisherInput!
    $condition: ModelSubpublisherConditionInput
  ) {
    updateSubpublisher(input: $input, condition: $condition) {
      id
      cd
      name
      planningDivision {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      subpublisherPlanningDivisionId
      __typename
    }
  }
`;
export const deleteSubpublisher = /* GraphQL */ `
  mutation DeleteSubpublisher(
    $input: DeleteSubpublisherInput!
    $condition: ModelSubpublisherConditionInput
  ) {
    deleteSubpublisher(input: $input, condition: $condition) {
      id
      cd
      name
      planningDivision {
        id
        cd
        name
        createdBy
        sortKey
        createdAt
        updatedAt
        __typename
      }
      createdBy
      sortKey
      createdAt
      updatedAt
      subpublisherPlanningDivisionId
      __typename
    }
  }
`;
export const createPlanningDivision = /* GraphQL */ `
  mutation CreatePlanningDivision(
    $input: CreatePlanningDivisionInput!
    $condition: ModelPlanningDivisionConditionInput
  ) {
    createPlanningDivision(input: $input, condition: $condition) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlanningDivision = /* GraphQL */ `
  mutation UpdatePlanningDivision(
    $input: UpdatePlanningDivisionInput!
    $condition: ModelPlanningDivisionConditionInput
  ) {
    updatePlanningDivision(input: $input, condition: $condition) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlanningDivision = /* GraphQL */ `
  mutation DeletePlanningDivision(
    $input: DeletePlanningDivisionInput!
    $condition: ModelPlanningDivisionConditionInput
  ) {
    deletePlanningDivision(input: $input, condition: $condition) {
      id
      cd
      name
      createdBy
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverwriteArticle = /* GraphQL */ `
  mutation CreateOverwriteArticle(
    $input: CreateOverwriteArticleInput!
    $condition: ModelOverwriteArticleConditionInput
  ) {
    createOverwriteArticle(input: $input, condition: $condition) {
      active
      article_code
      controller
      controller_confirmed
      createdBy
      manager
      manager_confirmed
      mandator_cd
      marketing_type_cd
      article_displayname
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      reason
      usrmail
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      delete_marked
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverwriteArticle = /* GraphQL */ `
  mutation UpdateOverwriteArticle(
    $input: UpdateOverwriteArticleInput!
    $condition: ModelOverwriteArticleConditionInput
  ) {
    updateOverwriteArticle(input: $input, condition: $condition) {
      active
      article_code
      controller
      controller_confirmed
      createdBy
      manager
      manager_confirmed
      mandator_cd
      marketing_type_cd
      article_displayname
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      reason
      usrmail
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      delete_marked
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverwriteArticle = /* GraphQL */ `
  mutation DeleteOverwriteArticle(
    $input: DeleteOverwriteArticleInput!
    $condition: ModelOverwriteArticleConditionInput
  ) {
    deleteOverwriteArticle(input: $input, condition: $condition) {
      active
      article_code
      controller
      controller_confirmed
      createdBy
      manager
      manager_confirmed
      mandator_cd
      marketing_type_cd
      article_displayname
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      reason
      usrmail
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      delete_marked
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverwriteWKZ = /* GraphQL */ `
  mutation CreateOverwriteWKZ(
    $input: CreateOverwriteWKZInput!
    $condition: ModelOverwriteWKZConditionInput
  ) {
    createOverwriteWKZ(input: $input, condition: $condition) {
      active
      controller
      controller_confirmed
      createdBy
      delete_marked
      manager
      manager_confirmed
      mandator_cd
      marketing_cd
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      usrmail
      reason
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverwriteWKZ = /* GraphQL */ `
  mutation UpdateOverwriteWKZ(
    $input: UpdateOverwriteWKZInput!
    $condition: ModelOverwriteWKZConditionInput
  ) {
    updateOverwriteWKZ(input: $input, condition: $condition) {
      active
      controller
      controller_confirmed
      createdBy
      delete_marked
      manager
      manager_confirmed
      mandator_cd
      marketing_cd
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      usrmail
      reason
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverwriteWKZ = /* GraphQL */ `
  mutation DeleteOverwriteWKZ(
    $input: DeleteOverwriteWKZInput!
    $condition: ModelOverwriteWKZConditionInput
  ) {
    deleteOverwriteWKZ(input: $input, condition: $condition) {
      active
      controller
      controller_confirmed
      createdBy
      delete_marked
      manager
      manager_confirmed
      mandator_cd
      marketing_cd
      ngw
      ngw_based_costs_percentage
      ngw_based_costs_percentage_overwritten
      ngw_cost
      ngw_cost_original
      ngw_cost_overwritten_value
      ngw_costs_overwritten
      ngw_factor_overwritten_value
      usrmail
      reason
      subpublisher_displayname
      timestamp
      valid_from
      valid_until
      expired
      handled
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverwriteNGW = /* GraphQL */ `
  mutation CreateOverwriteNGW(
    $input: CreateOverwriteNGWInput!
    $condition: ModelOverwriteNGWConditionInput
  ) {
    createOverwriteNGW(input: $input, condition: $condition) {
      active
      anfo_month
      article_cd
      article_code
      article_displayname
      cas_00_r03
      cas_36_r03
      condition_group
      controller
      controller_confirmed
      createdBy
      delete_marked
      expired
      handled
      mandator_cd
      manager
      manager_confirmed
      marketing_type_cd
      marketing_sub_type_cd
      ov_cp
      ov_cp_minMax
      ov_cp_contractPayedRatioThreshold
      ov_m
      ov_m_minMax
      ov_m_monthThreshold
      reason
      subpublisher_displayname
      timestamp
      usrmail
      valid_from
      valid_until
      ngwValue
      newNGW
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverwriteNGW = /* GraphQL */ `
  mutation UpdateOverwriteNGW(
    $input: UpdateOverwriteNGWInput!
    $condition: ModelOverwriteNGWConditionInput
  ) {
    updateOverwriteNGW(input: $input, condition: $condition) {
      active
      anfo_month
      article_cd
      article_code
      article_displayname
      cas_00_r03
      cas_36_r03
      condition_group
      controller
      controller_confirmed
      createdBy
      delete_marked
      expired
      handled
      mandator_cd
      manager
      manager_confirmed
      marketing_type_cd
      marketing_sub_type_cd
      ov_cp
      ov_cp_minMax
      ov_cp_contractPayedRatioThreshold
      ov_m
      ov_m_minMax
      ov_m_monthThreshold
      reason
      subpublisher_displayname
      timestamp
      usrmail
      valid_from
      valid_until
      ngwValue
      newNGW
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverwriteNGW = /* GraphQL */ `
  mutation DeleteOverwriteNGW(
    $input: DeleteOverwriteNGWInput!
    $condition: ModelOverwriteNGWConditionInput
  ) {
    deleteOverwriteNGW(input: $input, condition: $condition) {
      active
      anfo_month
      article_cd
      article_code
      article_displayname
      cas_00_r03
      cas_36_r03
      condition_group
      controller
      controller_confirmed
      createdBy
      delete_marked
      expired
      handled
      mandator_cd
      manager
      manager_confirmed
      marketing_type_cd
      marketing_sub_type_cd
      ov_cp
      ov_cp_minMax
      ov_cp_contractPayedRatioThreshold
      ov_m
      ov_m_minMax
      ov_m_monthThreshold
      reason
      subpublisher_displayname
      timestamp
      usrmail
      valid_from
      valid_until
      ngwValue
      newNGW
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChatBotQuestion = /* GraphQL */ `
  mutation CreateChatBotQuestion(
    $input: CreateChatBotQuestionInput!
    $condition: ModelChatBotQuestionConditionInput
  ) {
    createChatBotQuestion(input: $input, condition: $condition) {
      answer_length
      chat_model
      feedback
      helpful
      question
      timestamp
      user
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChatBotQuestion = /* GraphQL */ `
  mutation UpdateChatBotQuestion(
    $input: UpdateChatBotQuestionInput!
    $condition: ModelChatBotQuestionConditionInput
  ) {
    updateChatBotQuestion(input: $input, condition: $condition) {
      answer_length
      chat_model
      feedback
      helpful
      question
      timestamp
      user
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChatBotQuestion = /* GraphQL */ `
  mutation DeleteChatBotQuestion(
    $input: DeleteChatBotQuestionInput!
    $condition: ModelChatBotQuestionConditionInput
  ) {
    deleteChatBotQuestion(input: $input, condition: $condition) {
      answer_length
      chat_model
      feedback
      helpful
      question
      timestamp
      user
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
