import Vue from 'vue';
import App from './App.vue';
import VueMask from 'v-mask';

import Vuelidate from 'vuelidate';
import Vuex from 'vuex';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'; //Globally import VTextField

import router from './router';
import store from './store';

import moment from './plugins/moment.js';

import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyEventBus, AmplifyPlugin } from 'aws-amplify-vue';
import aws_exports from './aws-exports';

Vue.use(AmplifyPlugin, AmplifyModules);

import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';

import vuetify from './plugins/vuetify';

const updatedAwsConfig = {
  ...aws_exports,
  aws_user_pools_id: process.env.VUE_APP_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_COGNITO_POOL,
  userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
  region: process.env.VUE_APP_COGNITO_POOL_REGION,
  userPoolWebClientId: process.env.VUE_APP_COGNITO_POOL,
  oauth: {
    domain: process.env.VUE_APP_COGNITO_AUTH_DOMAIN,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.VUE_APP_URL,
    redirectSignOut: process.env.VUE_APP_URL,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
};

console.log(updatedAwsConfig);

Amplify.configure(updatedAwsConfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

AmplifyEventBus.$on('authState', info => {
  console.log(`Here is the auth event that was just emitted by an Amplify component: ${info}`);
});

Vue.config.productionTip = false;

Vue.use(VueMask);

Vue.use(moment);
Vue.use(Vuelidate);
Vue.use(Vuex);

Vue.component('v-text-field', VTextField);

Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
