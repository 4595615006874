import Vue from 'vue';
import Vuex from "vuex";

import createPersistedState from 'vuex-persistedstate'; // eslint-disable-line

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    signedIn: false,
    nboList: [],
    nboCounter: 0,
    language: 'de',
    jwtContent: '',
    jwtToken:'',
    OverWriteArticle_dropDownValues: null,
    OverWriteWKZ_dropDownValues: null,
    OverWriteNGW_dropDownValues: null,
    OverWriteArticle_approvalList: null,
    OverWriteNGW_approvalList: null,
    OverWriteNGW_items: null,
    OverWriteNGW_headers: null,
    OverWriteArticle_marketingMailLut: null,
    OverWriteWKZ_controllerMailLut: null,
    OverWriteNGW_controllerMailLut: null,
    reportsMenuList: null
  },
  getters: {
    user(state) {
      return state.user;
    },
    kurzel(state) {
      let kurzel = 'unbekannt';
      if (state.user && state.user.email) {
        kurzel = state.user.email.split('@')[0];
      }
      return kurzel;
    },
    signedIn(state) {
      return state.signedIn;
    },
    nboList(state) {
      return state.nboList;
    },
    nboCounter(state) {
      return state.nboCounter;
    },
    language(state) {
      return state.language
    },
    jwtContent(state) {
      return state.jwtContent;
    },
    jwtToken(state) {
      return state.jwtToken;
    },
    OverWriteArticle_dropDownValues(state) {
      return state.OverWriteArticle_dropDownValues;
    },
    OverWriteWKZ_dropDownValues(state) {
      return state.OverWriteWKZ_dropDownValues;
    },
    OverWriteNGW_dropDownValues(state) {
      return state.OverWriteNGW_dropDownValues;
    },
    OverWriteArticle_approvalList(state) {
      return state.OverWriteArticle_approvalList;
    },
    OverWriteNGW_approvalList(state) {
      return state.OverWriteNGW_approvalList;
    },
    OverWriteNGW_items(state) {
      return state.OverWriteNGW_items;
    },
    OverWriteNGW_headers(state) {
      return state.OverWriteNGW_headers;
    },
    OverWriteArticle_marketingMailLut(state) {
      return state.OverWriteArticle_marketingMailLut;
    },
    OverWriteWKZ_controllerMailLut(state) {
      return state.OverWriteWKZ_controllerMailLut;
    },
    OverWriteNGW_controllerMailLut(state) {
      return state.OverWriteNGW_controllerMailLut;
    },
    reportsMenuList(state) {
      return state.reportsMenuList;
    }
  },
  mutations: {
    setUser(state, input) {
      state.user = input;
    },
    setSignedIn(state, input) {
      state.signedIn = input;
    },
    setNboList(state, input) {
      state.nboList = input;
    },
    setNboCounter(state, input) {
      state.nboCounter = input;
    },
    setLanguage(state, input) {
      state.language = input;
    },
    setJwtContent(state, input) {
      state.jwtContent = input;
    },
    setJwtToken(state, input) {
      state.jwtToken = input;
    },
    setOverWriteArticle_dropDownValues(state, input) {
      state.OverWriteArticle_dropDownValues = input;
    },
    setOverWriteWKZ_dropDownValues(state, input) {
      state.OverWriteWKZ_dropDownValues = input;
    },
    setOverWriteNGW_dropDownValues(state, input) {
      state.OverWriteNGW_dropDownValues = input;
    },
    setOverWriteArticle_approvalList(state, input) {
      state.OverWriteArticle_approvalList = input;
    },
    setOverWriteNGW_approvalList(state, input) {
      state.OverWriteNGW_approvalList = input;
    },
    setOverWriteNGW_items(state, input) {
      state.OverWriteNGW_items = input;
    },
    setOverWriteNGW_headers(state, input) {
      state.OverWriteNGW_headers = input;
    },
    setOverWriteArticle_marketingMailLut(state, input) {
      state.OverWriteArticle_marketingMailLut = input;
    },
    setOverWriteWKZ_controllerMailLut(state, input) {
      state.OverWriteWKZ_controllerMailLut = input;
    },
    setOverWriteNGW_controllerMailLut(state, input) {
      state.OverWriteNGW_controllerMailLut = input;
    },
    setReportsMenuList(state, input) {
      state.reportsMenuList = input;
    }
  },
  plugins: [createPersistedState()],
});
