<template>
  <v-app id="app" light>
    <v-app-bar app flat light height="45">
      <Header />
    </v-app-bar>
    <!-- <div>{{ 'activ?'+this.reportsActive}}</div> -->
    <!-- <p>{{ this.selectedMenu  }}</p> -->
    <v-navigation-drawer
      app
      permanent
      :mini-variant.sync="mini"
      color="#F5F5F5"
      :width="navWidth"
      left
      floating
    >
      <v-list-item nav>
        <router-link to="/" @click.native="closeReportsAndLeadgen()">
          <v-img
            contain
            :width="logoWidth"
            :height="logoHeight"
            style="margin-top: 5px"
            :src="logoPath"
            alt="Logo des VNR Verlags"
          />
        </router-link>
        <v-btn style="margin-left: 65px" text @click.stop="mini = !mini"
          ><v-icon right> mdi-chevron-left </v-icon></v-btn
        >
      </v-list-item>
      <v-list nav>
        <!-- menu search -->
        <v-list-item
          class="ma-0"
          style="padding: 5pt 5pt 0 5pt"
          v-if="this.signedIn && checkMenuRole('Reporting', this.items)"
        >
          <v-autocomplete
            class="pa-0 ma-0"
            :items="reportsMenuList"
            density="comfortable"
            append-icon=""
            placeholder="Search for Quicksight Report"
            prepend-inner-icon="mdi-magnify"
            rounded
            theme="light"
            variant="solo"
            background-color="white"
            v-on:change="changeSearchMenu"
            v-model="selectedMenu"
            clearable
            default=""
            @click:clear="$nextTick(() => (selectedMenu = null))"
          ></v-autocomplete>
        </v-list-item>

        <v-list-group
          v-for="item in filteredItems"
          :key="item.title"
          no-action
          :value="$route.path.startsWith(item.module)"
          v-model="item.active"
        >
          <template v-slot:activator>
            <span class="material-symbols-outlined">{{ item.action }}&nbsp;</span>
            <v-list-item-content>
              <v-list-item-title v-if="item.to" link @click="closeReportsAndLeadgen()">{{
                item.title
              }}</v-list-item-title>
              <v-list-item-title v-else>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-if="item.title === 'LeadGen' && item.items.length"
            style="padding-left: 10pt"
          >
            <v-list-item-content v-if="item.title === 'LeadGen'">
              <template>
                <v-treeview
                  activatable
                  hoverable
                  :items="item.items"
                  open-on-click
                  :active="leadgenActive"
                  :open="leadgenOpen"
                >
                  <template slot="label" slot-scope="{ item }">
                    <div
                      @click="onClickLeadGen(item)"
                      style="
                        width: 190px;
                        height: 40px;
                        line-height: 40px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                      "
                    >
                      {{ item.name }}
                    </div>
                  </template>
                </v-treeview>
              </template>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else-if="item.title === 'Reporting'" style="padding-left: 10pt">
            <!-- <router-link to="/reports/Reports" style="text-decoration: none; color: inherit"> -->
            <!-- :open-all="openReportMenu" -->
            <v-list-item-content v-if="item.title === 'Reporting'" style="overflow: hidden">
              <template>
                <v-treeview
                  activatable
                  hoverable
                  item-key="name"
                  :items="added_items"
                  :active="reportsActive"
                  :open="reportsOpen"
                  style="overflow: hidden"
                >
                  <template slot="label" slot-scope="{ item }">
                    <v-tooltip right style="overflow: hidden">
                      <template v-slot:activator="{ on }">
                        <div
                          v-on="on"
                          @click="onClick(item)"
                          style="
                            width: 190px;
                            height: 40px;
                            line-height: 40px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                          "
                        >
                          {{ item.name }}
                        </div>
                      </template>
                      <span> {{ item.name }}</span>
                    </v-tooltip>
                  </template>
                </v-treeview>
              </template>
            </v-list-item-content>
            <!-- </router-link> -->
          </v-list-item>
          <v-list-item
            v-else
            v-for="child in filterRoles(item.items)"
            :key="child.title"
            link
            :to="{ name: child.link }"
            @click="closeReportsAndLeadgen()"
          >
            <v-list-item-icon>
              <v-icon small color="primary"> mdi-folder-star-multiple </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main id="lateral">
      <v-container fluid>
        <router-view v-if="!showReports" />
        <Reports
          :title="rePortTitle"
          :embbedinglink="iframeLink"
          :description="reportDescription"
          :use_cases="use_cases"
          v-if="showReports"
        />
      </v-container>
    </v-main>
    <v-footer style="z-index: 1" absolute app dark color="secondary" />
  </v-app>
</template>

<script>
import Header from '@/components/menu/Header.vue';
import { mapGetters } from 'vuex';
import { API, I18n } from 'aws-amplify';
// import menuFile1 from '../src/assets/structure_output.json';
import Reports from '@/views/reports/Reports.vue';

export default {
  components: {
    Header,
    Reports,
  },
  computed: {
    ...mapGetters(['signedIn', 'user', 'language', 'reportsMenuList']),
    filteredItems() {
      return this.items.filter(
        (item) => !item.signedIn || (this.signedIn && this.filterRoles(item.items).length > 0)
      );
    },
    logoPath() {
      if (this.mini) {
        return require('@/assets/logo.png');
      } else {
        if (process.env.VUE_APP_ENV === 'dev') {
          return require('@/assets/logo-plx-dev.png');
        } else {
          return require('@/assets/logo-plx.png');
        }
      }
    },
    logoWidth() {
      if (this.mini === true) {
        return '27';
      }
      return '170';
    },
    logoHeight() {
      if (this.mini === true) {
        return '27';
      }
      return '50';
    },
    navWidth() {
      if (this.mini === true) {
        return '60';
      }
      return '320';
    },
    menuList() {
      let temp = this.menuForSearchWithOrder;
      if (this.selectedMenu != null) {
        // this.menuForSearchWithOrder = this.moveToTop(this.selectedMenu, temp);
        temp.unshift(temp.splice(temp.indexOf(this.selectedMenu), 1)[0]);
        console.log(temp);
        return temp;
      }
      // temp = temp.filter(i => (i != 'Dashboard für Fachverlage' && i != 'Anfo-Ticker'  && i!= 'Werbestatistik'  && i!= 'Vertriebsstatistik'  && i!= 'Rechnungs-Response Report'  && i!= 'PX Data Empfehlungen' ) )
      // temp = this.moveToTop('PX Data Empfehlungen',temp);
      // temp = this.moveToTop('Rechnungs-Response Report',temp);
      // temp = this.moveToTop('Vertriebsstatistik',temp);
      // temp = this.moveToTop('Werbestatistik',temp);
      // temp = this.moveToTop('Anfo-Ticker',temp);
      // temp = this.moveToTop('Dashboard für Fachverlage',temp);
      return temp;
    },
  },
  created() {
    document.title = 'PX Data';
    I18n.setLanguage(this.language);
    // this.getReportMenu();
    // this.createReportMenu(menuFile1);
  },
  mounted() {
    this.getReportMenu();
  },
  watch: {
    selectedMenu() {
      // this.reportsOpen.push(this.selectedMenu);
      if (this.selectedMenu) {
        this.menuParents.forEach((i) => {
          if (i.name == this.selectedMenu) {
            // console.log(i.parent);
            this.reportsOpen = i.parent;
          }
        });
      }
    },
  },
  name: 'DefaultLayout',
  data() {
    return {
      mini: false,
      drawer: true,
      showReports: false,
      reportsActive: [],
      reportsOpen: [],
      leadgenActive: [],
      leadgenOpen: [],
      rePortTitle: 'Report',
      iframeLink: '',
      use_cases: [],
      reportDescription: '',
      report_menus: [],
      selectedMenu: null,
      menuForSearch: [],
      openReportMenu: false,
      items: [
        {
          action: 'receipt_long',
          signedIn: true,
          active: false,
          title: 'NGW',
          module: 'ngw',
          items: [
            {
              title: 'Überschreibung NGW',
              link: 'InvoiceOverwriteNGWList',
              roles: [
                'Data-Author',
                'Data-Admin',
                'Data-Reader',
                'Data-UI-Invoice-Overwriter',
              ],
            },
          ],
        },
        {
          action: 'supervised_user_circle',
          signedIn: true,
          active: false,
          title: 'LeadGen',
          module: 'leadgen',
          items: [
            {
              id: 1,
              name: 'LeadGen-Kosten',
              // link: 'leadgenAgencyCostList',
              roles: ['Data-Author', 'Data-Reader'],
              children: [
                {
                  id: 11,
                  name: 'Agentur Kosten',
                  link: 'leadgenAgencyCostList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 12,
                  name: 'KOOP-Kosten',
                  link: 'leadgenCooperationCostList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
              ],
            },
            {
              id: 2,
              name: 'LeadGen-Stammdaten',
              // link: 'leadgenCooperationCostList',
              roles: ['Data-Author', 'Data-Reader'],
              children: [
                {
                  id: 21,
                  name: 'Affiliate Typ',
                  link: 'masterLeadgenAffiliateTypeList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 22,
                  name: 'Affiliate Hard Mapping',
                  link: 'masterLeadgenAffiliateHardMappingList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 23,
                  name: 'Agentur',
                  link: 'masterLeadgenAgencyList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 24,
                  name: 'Netzwerk',
                  link: 'masterLeadgenNetworkList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 25,
                  name: 'Newsletter',
                  link: 'masterLeadgenNewsletterList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 26,
                  name: 'Fachverlag',
                  link: 'masterLeadgenSubpublisherList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 27,
                  name: 'Zielgruppe',
                  link: 'masterLeadgenTargetGroupList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
                {
                  id: 28,
                  name: 'Planungsbereich',
                  link: 'masterLeadgenPlanningDivisionList',
                  roles: ['Data-Author', 'Data-Reader'],
                },
              ],
            },
          ],
        },
        {
          action: 'drive_file_rename_outline',
          signedIn: true,
          active: false,
          title: 'Abrechnung',
          module: 'invoice',
          items: [
            {
              title: 'Überschreibung Artikel',
              link: 'InvoiceOverwriteArticle',
              roles: [
                'Data-Author',
                'Data-Admin',
                'Data-Reader',
                'Data-UI-Invoice-Overwriter',
              ],
            },
            {
              title: 'Überschreibung WKZ',
              link: 'InvoiceOverwriteWKZList',
              roles: [
                'Data-Author',
                'Data-Admin',
                'Data-Reader',
                'Data-UI-Invoice-Overwriter',
              ],
            },
          ],
        },
        {
          action: 'trending_up',
          signedIn: true,
          active: false,
          title: 'Reporting',
          module: 'Reports',
          to: 'Reports',
          items: [
            {
              link: 'Reports',
              roles: ['Data-Author', 'Data-Admin', 'Data-Reader'],
            },
          ],
        },
      ],
      added_items: [],
      menuParents: [],
    };
  },
  methods: {
    getReportMenu: function () {
      this.loading = true;
      API.get('datauiRestAPI', '/reportMenu', {})
        .then((result) => {
          let temp = JSON.parse(result);
          this.createReportMenu(temp);
          this.createMenuParentMapping(this.added_items);
          // console.log('DEBUG REPORTS: '+Object.keys(temp));
        })
        .catch((err) => {
          console.log(err);
        });
      this.loading = false;
    },
    hasRole(role) {
      if (role.includes('All')) {
        return true;
      }
      if (!this.user || !this.user['custom:group']) {
        return false;
      }
      return this.user['custom:group'].includes(role);
    },
    filterRoles(itemArray) {
      const roles = itemArray[0].roles;
      if (!roles) {
        return itemArray;
      }
      return itemArray.filter((item) => item.roles.some((role) => this.hasRole(role)));
    },
    checkMenuRole(menuTitle, list) {
      let objIndex = list.findIndex((obj) => obj.title == menuTitle);
      return list[objIndex].items[0].roles.some((role) => this.hasRole(role));
    },
    onClick(item) {
      if (item.to) {
        this.showReports = true;
        this.rePortTitle = item.name;
        this.iframeLink = item.to;
        this.reportDescription = item.description;
        this.use_cases = item.use_cases;
        let query = item.to.split('dashboards/')[1];
        this.$router
          .push({ path: '/reports/Reports/', query: { dashboard: query } })
          .catch(() => {});
        console.log('click: ' + this.iframeLink.split('dashboards/')[1]);
      }
    },
    onClickLeadGen(item) {
      console.log(item.title);
      if (item.link && this.$route.name !== item.link) {
        this.$router.push({ name: item.link });
      }
    },
    closeReports() {
      this.showReports = false;
      this.reportsActive = [];
      this.reportsOpen = [];
    },
    closeReportsAndLeadgen() {
      this.showReports = false;
      this.reportsActive = [];
      this.reportsOpen = [];
      this.leadgenActive = [];
      this.leadgenOpen = [];
    },
    randomString() {
      let length = 6 + Math.floor(Math.random() * 4);
      let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    treeMenuItem(parent) {
      let temp = parent.structure_children;
      let menuItems = [];
      for (const [key, value] of Object.entries(temp)) {
        // console.log('debug key: '+key);
        let childArr = [];
        value.structure_children.map((i) => {
          let childObj = {
            id: this.randomString(),
            name: Object.keys(i)[0],
            to: Object.values(i)[0].embbeding_link,
            description: Object.values(i)[0].description,
            use_cases: Object.values(i)[0].use_cases,
          };
          childArr.push(childObj);
        });
        let obj = {
          id: this.randomString(),
          name: key,
          children: childArr,
        };
        menuItems.push(obj);
      }
      return menuItems;
    },
    createReportMenu(menuFile) {
      let autoCreateMenus = [];
      Object.keys(menuFile).forEach((i) => {
        let obj = {
          id: this.randomString(),
          name: i,
          action: 'table',
          signedIn: true,
          active: false,
          title: i,
          // embbeding_link: menuFile[i].embbeding_link,
          module: 'Reports',
          // to: 'Reports',
          to: menuFile[i].embbeding_link,
          items: [
            {
              link: 'Reports',
              roles: ['All'],
            },
          ],
          children: this.treeMenuItem(menuFile[i]),
        };
        autoCreateMenus.push(obj);
      });
      this.added_items = autoCreateMenus;
      // console.log(JSON.stringify(this.added_items))
      this.added_items.forEach((item) => {
        if (item.name) {
          this.menuForSearch.push({ name: item.name, to: item.to, id: item.id });
        }
        if (item.children.length) {
          item.children.forEach((i) => {
            if (i.name) {
              this.menuForSearch.push({ name: i.name, to: i.to, id: item.id });
            }
            if (i.children.length) {
              i.children.forEach((j) => {
                if (j.name) {
                  this.menuForSearch.push({ name: j.name, to: j.to, id: item.id });
                }
              });
            }
          });
        }
        let tempList = this.menuForSearch.map((i) => i.name);
        tempList = this.moveToTop('PX-Data Empfehlungen', tempList);
        tempList = this.moveToTop('Rechnungs-Response Report', tempList);
        tempList = this.moveToTop('Vertriebsstatistik', tempList);
        tempList = this.moveToTop('Werbestatistik', tempList); // third
        tempList = this.moveToTop('Anfo-Ticker', tempList); // second
        tempList = this.moveToTop('Dashboard für Fachverlage', tempList); //first one
        this.$store.commit('setReportsMenuList', tempList);
      });
    },
    createMenuParentMapping(items) {
      items.forEach((i) => {
        // console.log(i.name);
        if (i.children) {
          i.children.forEach((j) => {
            this.menuParents.push({ name: j.name, parent: [i.name] });
            // console.log(j.name);
            if (j.children && j.children.length > 0) {
              j.children.forEach((k) => {
                this.menuParents.push({ name: k.name, parent: [i.name, j.name] });
                // console.log(k.name);
              });
            }
          });
        }
      });
    },
    changeSearchMenu() {
      if (this.selectedMenu) {
        let selectedItem = this.menuForSearch.filter((i) => i.name == this.selectedMenu)[0];
        this.reportsActive.push(selectedItem.name);
        let objIndex = this.filteredItems.findIndex((obj) => obj.title == 'Reporting');
        this.filteredItems[objIndex].active = true;
        this.openReportMenu = true;
        this.filteredItems.filter((i) => i.title == 'Reporting').active = true;
        if (Object.keys(selectedItem).includes('to') && selectedItem.to) {
          let query = selectedItem.to.split('dashboards/')[1];
          this.$router
            .push({ path: '/reports/Reports/', query: { dashboard: query } })
            .catch(() => {});
        }
        //set order for the menu list
        this.$store.commit(
          'setReportsMenuList',
          this.moveToTop(this.selectedMenu, this.reportsMenuList)
        );
      }
    },
    moveToTop(item, list) {
      if (list.length > 0 && list.indexOf(item)) {
        list.unshift(list.splice(list.indexOf(item), 1)[0]);
      }
      return list;
    },
  },
};
</script>

<style>
* {
  --amplify-primary-color: #0168a5;
  --amplify-primary-tint: #1671a5;
  --amplify-primary-shade: #024d79;
}

.no-underline {
  text-decoration: none !important;
}
.material-symbols-outlined {
  color: #707070;
}
</style>
