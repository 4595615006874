<template>
  <v-container fluid>
    <BaseTable
      :items="items"
      :headers="headers"
      :title="tableConfig.listHeader"
      :componentName="`${tableConfig.category}${tableConfig.tableName}`"
      :tableName="`${tableConfig.tableNamePlural}`"
      :editMutation="`update${tableConfig.tableName}`"
      :deleteMutation="`delete${tableConfig.tableName}`"
      :isDuplicateButton="tableConfig.isDuplicateButton"
      @refresh="updateList"
      @loadMore="onLoadMoreDataClicked"
      :loading="loading"
      :nextToken="nextToken"
      :sortingKey="sortingKey"
    />
  </v-container>
</template>

<script>
import { API } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import BaseTable from '@/components/BaseTable.vue';

export default {
  name: 'DefaultTable',
  components: {
    BaseTable
  },
  props: {
    config: {
      type: Array
    },
    tableConfig: {
      type: Object
    }
  },
  async created() {
    this.updateList();
  },
  methods: {
    updateList() {
      this.getList();
    },
    // async onLoadMoreDataClicked() {
    //   this.loading = true;
    //   const queryName = this.tableConfig.query
    //     ? this.tableConfig.query
    //     : `list${this.tableConfig.tableName}s`;
    //   const variables = this.tableConfig.variables ? this.tableConfig.variables : { limit: 500 };
    //   const response = await API.graphql({
    //     query: queries[queryName],
    //     variables: variables
    //   });
    //   this.items = this.items.concat(response.data[queryName].items);
    //   this.nextToken = response.data[queryName].nextToken;
    //   this.loading = false;
    // },
    async onLoadMoreDataClicked() {
      this.loading = true;
      let response;
      const queryName = this.tableConfig.query
        ? this.tableConfig.query
        : `list${this.tableConfig.tableName}s`;
      let variables = this.tableConfig.variables ? this.tableConfig.variables : { limit: 500 };
      variables.nextToken = this.nextToken;
      if (this.tableConfig.tableName === 'AgencyCost') {
        //get all related tables
        const agency_cd_list = (
          await API.graphql({
            query: queries['listAgencies'],
            variables: {
              limit: 500
            }
          })
        ).data.listAgencies.items;
        // console.log('debug agencies: ', agency_cd_list);
        const network_cd_list = (
          await API.graphql({
            query: queries['listNetworks'],
            variables: {
              limit: 500
            }
          })
        ).data.listNetworks.items;
        // console.log('debug network: ', network_cd_list);
        const affiliateType_cd_list = (
          await API.graphql({
            query: queries['listAffiliateTypes'],
            variables: {
              limit: 500
            }
          })
        ).data.listAffiliateTypes.items;
        // console.log('debug AffiliateType: ', affiliateType_cd_list);
        const targetGroup_list = (
          await API.graphql({
            query: queries['listTargetGroups'],
            variables: {
              limit: 500
            }
          })
        ).data.listTargetGroups.items;
        // console.log('debug listTargetGroups: ', targetGroup_cd_list);
        const subpublisher_cd_list = (
          await API.graphql({
            query: queries['listSubpublishers'],
            variables: {
              limit: 500
            }
          })
        ).data.listSubpublishers.items;
        // console.log('debug subpublisher_cd_list: ', subpublisher_cd_list);
        const newsletter_cd_list = (
          await API.graphql({
            query: queries['listNewsletters'],
            variables: {
              limit: 500
            }
          })
        ).data.listNewsletters.items;

        response = await API.graphql({
          query: queries[queryName],
          variables: variables,
          nextToken: this.nextToken,
        });
        //this.items = this.items.concat(response.data[queryName].items);
        let res = response.data[queryName].items;
        for (let i in res) {
          let item = res[i];
          item['agency.cd'] = agency_cd_list.find(x => x.id === item.agencyCostAgencyId)
            ? agency_cd_list.find(x => x.id === item.agencyCostAgencyId).cd
            : '';
          item['network.cd'] = network_cd_list.find(x => x.id === item.agencyCostNetworkId)
            ? network_cd_list.find(x => x.id === item.agencyCostNetworkId).cd
            : '';
          item['affiliateType.cd'] = affiliateType_cd_list.find(
            x => x.id === item.agencyCostAffiliateTypeId
          )
            ? affiliateType_cd_list.find(x => x.id === item.agencyCostAffiliateTypeId).cd
            : '';
          item['targetGroup.name'] = targetGroup_list.find(
            x => x.id === item.agencyCostTargetGroupId
          )
            ? targetGroup_list.find(x => x.id === item.agencyCostTargetGroupId).name
            : '';
          item['subpublisher.cd'] = subpublisher_cd_list.find(
            x => x.id === item.agencyCostSubpublisherId
          )
            ? subpublisher_cd_list.find(x => x.id === item.agencyCostSubpublisherId).cd
            : '';
          item['newsletter.cd'] = newsletter_cd_list.find(x => x.id === item.agencyCostNewsletterId)
            ? newsletter_cd_list.find(x => x.id === item.agencyCostNewsletterId).cd
            : '';
          this.items = this.items.concat(item);
        }
      } else {
        response = await API.graphql({
          query: queries[queryName],
          variables: variables,
          nextToken: this.nextToken,
        });
        this.items = this.items.concat(response.data[queryName].items);
      }
      this.nextToken = response.data[queryName].nextToken;
      this.loading = false;
    },
    async getList() {
      this.loading = true;
      let response;
      const queryName = this.tableConfig.query
        ? this.tableConfig.query
        : `list${this.tableConfig.tableName}s`;
      const variables = this.tableConfig.variables ? this.tableConfig.variables : { limit: 500 };
      if (this.tableConfig.tableName === 'AgencyCost') {
        //get all related tables
        const agency_cd_list = (
          await API.graphql({
            query: queries['listAgencies'],
            variables: {
              limit: 500
            }
          })
        ).data.listAgencies.items;
        // console.log('debug agencies: ', agency_cd_list);
        const network_cd_list = (
          await API.graphql({
            query: queries['listNetworks'],
            variables: {
              limit: 500
            }
          })
        ).data.listNetworks.items;
        // console.log('debug network: ', network_cd_list);
        const affiliateType_cd_list = (
          await API.graphql({
            query: queries['listAffiliateTypes'],
            variables: {
              limit: 500
            }
          })
        ).data.listAffiliateTypes.items;
        // console.log('debug AffiliateType: ', affiliateType_cd_list);
        const targetGroup_list = (
          await API.graphql({
            query: queries['listTargetGroups'],
            variables: {
              limit: 500
            }
          })
        ).data.listTargetGroups.items;
        // console.log('debug listTargetGroups: ', targetGroup_cd_list);
        const subpublisher_cd_list = (
          await API.graphql({
            query: queries['listSubpublishers'],
            variables: {
              limit: 500
            }
          })
        ).data.listSubpublishers.items;
        // console.log('debug subpublisher_cd_list: ', subpublisher_cd_list);
        const newsletter_cd_list = (
          await API.graphql({
            query: queries['listNewsletters'],
            variables: {
              limit: 500
            }
          })
        ).data.listNewsletters.items;

        response = await API.graphql({
          query: queries[queryName],
          variables: variables
        });
        let res = response.data[queryName].items;
        for (let i in res) {
          let item = res[i];
          item['agency.cd'] = agency_cd_list.find(x => x.id === item.agencyCostAgencyId)
            ? agency_cd_list.find(x => x.id === item.agencyCostAgencyId).cd
            : '';
          item['network.cd'] = network_cd_list.find(x => x.id === item.agencyCostNetworkId)
            ? network_cd_list.find(x => x.id === item.agencyCostNetworkId).cd
            : '';
          item['affiliateType.cd'] = affiliateType_cd_list.find(
            x => x.id === item.agencyCostAffiliateTypeId
          )
            ? affiliateType_cd_list.find(x => x.id === item.agencyCostAffiliateTypeId).cd
            : '';
          item['targetGroup.name'] = targetGroup_list.find(
            x => x.id === item.agencyCostTargetGroupId
          )
            ? targetGroup_list.find(x => x.id === item.agencyCostTargetGroupId).name
            : '';
          item['subpublisher.cd'] = subpublisher_cd_list.find(
            x => x.id === item.agencyCostSubpublisherId
          )
            ? subpublisher_cd_list.find(x => x.id === item.agencyCostSubpublisherId).cd
            : '';
          item['newsletter.cd'] = newsletter_cd_list.find(x => x.id === item.agencyCostNewsletterId)
            ? newsletter_cd_list.find(x => x.id === item.agencyCostNewsletterId).cd
            : '';
          this.items.push(item);
        }
      } else {
        response = await API.graphql({
          query: queries[queryName],
          variables: variables
        });
        this.items = response.data[queryName].items;
      }

      this.nextToken = response.data[queryName].nextToken;
      this.loading = false;
    }
  },
  data() {
    return {
      items: [],
      nextToken: null,
      loading: true
    };
  },
  computed: {
    headers() {
      const returnObj = [];
      this.config.forEach(item => {
        if (item.inTable) {
          if (item.fieldType === 'refDropdown') {
            returnObj.push({
              text: item.name,
              value: item.referenceTableDisplay
            });
          } else if (item.fieldType === 'localRefDropdown') {
            returnObj.push({
              text: item.name,
              value: item.techName
            });
          } else {
            returnObj.push({
              text: item.name,
              value: item.techName
            });
          }
        }
      });
      return returnObj;
    },
    sortingKey() {
      if (this.tableConfig.sortingKey) {
        return this.tableConfig.sortingKey;
      }
      return 'cd';
    }
  }
};
</script>
